import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../utils/firebase";
import TopBar from "../../components/TopBar/TopBar";
// import SelectUSState from 'react-select-us-states';
// import stateCodes from 'us-state-codes';
import './Signup.scss';

const Signup: React.FC = () => {
  const navigate = useNavigate();
  const [userVar, setUser] = useState<any>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        navigate("/");
      }
    });
    return unsubscribe;
  }, [navigate]);

  const [isOrganization, setIsOrganization] = useState(false);
  const [name, setName] = useState<string>("");
  const [phonenumber,setPhonenumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [repeatPassword, setRepeatPassword] = useState(""); 

  const handleSignup = async (e: React.FormEvent) => {
    e.preventDefault();

    if (password !== repeatPassword) {
      setError("Passwords do not match");
      return;
    }
  
    try {
      setError("");

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      navigate("/");
    } catch (error: any) {
      setError(error.message);
    }
  };

  // const states = stateCodes.getAllStateNames();
  const states = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
    'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
    'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
    'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
    'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio',
    'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
    'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia',
    'Wisconsin', 'Wyoming'
  ];

  return (
    <div className="page-container">
      <TopBar className="title" title="Sign up" />
      <div className="signup-container">
        <form onSubmit={handleSignup}>
          <h2>SIGN UP</h2>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="organization-checkbox"
              checked={isOrganization}
              onChange={() => setIsOrganization(!isOrganization)}
            />
            <label htmlFor="organization-checkbox">Register as an Organization</label>
          </div>

          {isOrganization && (
            <>
              <div>
                <label>Dealership Name:</label>
                <input type="text" required />
              </div>
              <div>
                <label>DIN:</label>
                <input type="text" required />
              </div>
              <div>
                <label>Business Address:</label>
                <input type="text" required />
              </div>
              <div>
                <label>Business Apartment, suite, unit, etc.:</label>
                <input type="text" />
              </div>
              <div className="two-column">
                <div>
                  <label>Zip:</label>
                  <input type="zip" required />
                </div>
                <div>
                  <label>Business City, State/Province:</label>
                  <input type="state" required />
                </div>
              </div>
              {/* <div>
                <label>Business State:</label>
                <select required>
                  <option value="">Select State</option>
                </select>
              </div> */}
              <div>
                <label>Business State:</label>
                <select required>
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}

          <div>
            <label>Full Name:</label>
            <input
              type="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <div>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Phone Number:</label>
            <input
              type="phonenumber"
              // Logic for phone Number
              value={phonenumber}
              onChange={(e) => setPhonenumber(e.target.value)}
              required

            />
          </div>

          <div>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Repeat Password:</label>
            <input
              type="password"
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              required
            />
          </div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <button type="submit">Sign Up</button>
  
          <div className="divider">
            <span>OR</span>
          </div>
          <div className="social-icons">
          </div>
          <div className="login-link">
            Already a user? <a href="/signin">SIGN IN</a>
          </div>
        </form>
      </div>
    </div>
  );
  
};

export default Signup;
