import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Home from "./views/Home/Home";
import Signin from "./views/SignIn/Signin";
import Signup from "./views/SignUp/Signup";
import { UserProvider } from "./context/UserContext";
import Account from "./views/Account/Account";
import { sendPageView } from "./utils/analytics";
import { auth } from "./utils/firebase";

function App() {

  const [userVar, setUser] = useState<any>(null);
  const navigate = useNavigate();
  useEffect(() => {
    // Send page view when the route changes
    sendPageView('/scout')
  }, [])

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (!user) {
        navigate("/signin");
      }
    });
    return unsubscribe;
  }, [navigate]);
  return (

    <UserProvider>
      <Routes>
        <Route path="/" element={userVar ? <Home /> : <Signin />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/account" element={userVar ? <Account email={userVar?.email} /> : <Signin />} />
      </Routes>
    </UserProvider>
  );
}

export default App;
