// src/pages/Account.tsx
import React, { useEffect } from "react";
import { Button } from "antd";
import { signout } from "../../utils/Signout";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import TopBar from "../../components/TopBar/TopBar"; // Import the TopBar component
import "./Account.scss"; // Add any necessary styles for this page
import profile from "../../assets/Profile.png";

interface AccountProps {
  email: string | null;
}

const Account: React.FC<AccountProps> = ({ email }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    await signout();
    navigate("/signin");
  };

  useEffect(() => {
    console.log("User email in Account component:", email);
  }, [email]);

  return (
    <div className="account-page">
      <TopBar title="Account" />
      <div className="account-content">
        <h1>Account</h1>
        <p>All About You</p>
        <div className="profile-picture">
          <img
            src={profile}
            alt="Profile"
            className="profile-img"
          />
        </div>
        <p className="email">{email}</p>
        <div className="button-group">
          <Button type="primary" className="account-button" onClick={handleLogout}>
            Log Out
          </Button>
          <Button type="primary" className="account-button" href="https://app.pacer.co/#/account" target="_blank">
            Profile
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Account;
