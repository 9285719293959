export interface CarCardProps {
    key: string;
    name: string;
    currentBid: string;
    timeLeft: string;
    location: string;
    website: string;
    reserveStatus: string;
    image: string;
    link: string;
    AveragePrice: any;
    Favorited: boolean;
}

export interface FilterValues {
    selectedMakes: string[];
    selectedModels: string[];
    websites: string[];
    reserve: string[];
    startYear: string;
    endYear: string;
    location: string;
    distance: string;
    // Add other filter values here
}

export const defaultFilterValues = {
    selectedMakes: [],
    selectedModels: [],
    websites: [],
    reserve: [],
    startYear: '',
    endYear: '',
    location: '',
    distance: '',
    };